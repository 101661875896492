//
//
//
//
//
//
//

import Components from '@/components/dynamicComponent'

export default {
  components: {
    Components
  },
  data() {
    return {
      page: [],
      error: null
    }
  },
  computed: {
    d () {
      // const re = this.$store.getters.cmsdata;
      let slug = this.$route.params.slug;
      if(slug === 'index.html.var'){slug='home'};
      return this.$store.getters.cmsdata[slug][0]
    }
  },
  async asyncData({
    params,
    error,
    store
  }) {
    // console.log("async page _slug: ", params.slug);
    try {
      await store.dispatch('getData', params.slug)
    } catch (e) {
      error({
        statusCode: e.statusCode,
        message: e.message
      })
    }
  },
}

