//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    // heroes
    'heroes.hero-image': () => import('@/components/heroes/HeroImage'),
    'heroes.hero-slider': () => import('@/components/heroes/HeroHomeSlider'),
    // sections
    'sections.section-awards': () => import('@/components/sections/SectionAwards'),
    'sections.section-content': () => import('@/components/sections/SectionContent'),
    'sections.section-content-image': () => import('@/components/sections/SectionContentImage'),
    'sections.section-content-quote': () => import('@/components/sections/SectionContentQuote'),
    'sections.section-cta': () => import('@/components/sections/SectionCta'),
    'sections.section-gallery': () => import('@/components/sections/SectionGallery'),
    'sections.section-gallery-grid': () => import('@/components/sections/SectionGalleryGrid'),
    'sections.section-home-tiles': () => import('@/components/sections/SectionHomeTiles'),
    'sections.section-tiles': () => import('@/components/sections/SectionTiles'),
    'sections.section-posts-slider': () => import('@/components/sections/SectionPostsSlider'),
    'sections.section-projects-slider': () => import('@/components/sections/SectionProjectsSlider'),
    'sections.section-icon-slider': () => import('@/components/sections/SectionIconSlider'),
    'sections.section-projects-grid': () => import('@/components/sections/SectionProjectsGrid'),
    'sections.section-projects-map': () => import('@/components/sections/SectionProjectsMap'),
    'sections.section-map': () => import('@/components/sections/SectionMap'),
    'sections.section-contact-form': () => import('@/components/sections/SectionContactForm'),
    'sections.section-news': () => import('@/components/sections/SectionNews'),
    'sections.section-news-recent': () => import('@/components/sections/SectionNewsRecent'),
    'sections.section-image': () => import('@/components/sections/SectionImage'),
    // posts
    'posts.post-hero': () => import('@/components/posts/PostHero'),
    'posts.post-content': () => import('@/components/posts/PostContent'),
    // projects
    'projects.project-hero': () => import('@/components/projects/ProjectHero'),
    'projects.project-gallery-grid': () => import('@/components/projects/ProjectGalleryGrid'),
    'projects.related-projects-slider': () => import('@/components/projects/RelatedProjectSlider')
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  }
}

